var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"text-align":"center","height":"100vh","width":"100vw"}},[_c('div',{staticClass:"flex-v",style:({
      width: '100%',
      height: '100%',
      'background-image': 'url(' + _vm.picUrl + ')',
      'background-repeat': 'no-repeat',
      'background-size': '100% 100%',
      '-moz-background-size': '100% 100%',
    })},[_c('div',{staticStyle:{"padding-top":"10vh"}},[_c('van-image',{attrs:{"width":"70%","src":_vm.title}})],1),_c('div',{staticStyle:{"padding-top":"5vh","padding-bottom":"5vh"}},[_c('van-image',{staticClass:"buttonStyle",attrs:{"width":"30%","src":_vm.button},on:{"click":_vm.go}})],1),_c('van-row',[_c('van-col',{attrs:{"span":"3"}}),_c('van-col',{attrs:{"span":"18"}},[_c('div',{style:({
            width: '100%',
            padding: '4px 10px',
            'background-image': 'url(' + _vm.rule + ')',
            'background-repeat': 'no-repeat',
            'background-size': '100% 100%',
            '-moz-background-size': '100% 100%',
          })},[_c('van-divider',{staticStyle:{"color":"white","font-weight":"600"}},[_vm._v("活动规则")]),_c('div',{staticStyle:{"color":"white","font-size":"14px","padding":"0 10px 20px 10px","text-align":"start"}},[_vm._v(" 每天有5次抽卡机会，阅读完一张卡片的楼宇简介后即可收卡成功，有多余的卡片可分享给好友并获得一次额外的抽卡机会，集齐九座楼宇的卡片即可参与抽奖。 ")])],1)]),_c('van-col',{attrs:{"span":"3"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }